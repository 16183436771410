import React, {
	ChangeEvent,
	FormEvent,
	useEffect,
	useRef,
	useState,
} from "react"
import { Input, PrimaryBtn } from "components/library"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { NewsletterIllustration } from "components/library/illustrations/newsletter-illustration";

const API_URL = `${process.env.GATSBY_BACKEND_API_V1}/subscribe_to_newsletter`

const nullData = {
	name: "",
	company: "",
	email: "",
}

export const Newsletter = () => {
	const [data, setData] = useState(nullData)
	const [isBtnDisabled, setIsBtnDisabled] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [error, setError] = useState<string>()
	const isInputTouched = useRef(false)
	const { t } = useTranslation()

	function handleDataChange(e: ChangeEvent<HTMLInputElement>) {
		const { name, value } = e.target
		if (!isInputTouched.current) {
			setIsBtnDisabled(true)
			isInputTouched.current = true
		}
		setData({ ...data, [name]: value })
	}

	useEffect(() => {
		if (data.company && data.email && data.name) setIsBtnDisabled(false)
	}, [data])

	async function handleNewsletterSubscribe(e: FormEvent) {
		e.preventDefault()
		try {
			setIsSubmitting(true)
			const response = await fetch(API_URL, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					email: data.email,
					name: data.name,
					company_name: data.company,
				}),
			})
			const result: {
				result: { status: "Failed" | "Success"; message: string }
			} = await response.json()
			if (result.result.status === "Failed")
				return setError(result.result.message)
			setData(nullData)
			setIsSubmitted(true)
		} catch (err) {
			console.log({ err })
			setError(t("common.messages.internal-server-error"))
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<section className="bg-gradient-to-b flex justify-between p-10 lg:p-10 lg:bg-gradient-to-r from-[#3C67F0] to-[#F5B581] rounded-sm">
			{!isSubmitted && !error && (
				<form
					onSubmit={handleNewsletterSubscribe}
					className="w-full lg:w-1/2 flex flex-col gap-6"
				>
					<h3 className="text-white text-4xl lg:text-5xl">
						{t("newsletter.main.title")}
					</h3>
					<p className="text-white text-lg">{t("newsletter.main.desc")}</p>

					<div className="flex flex-col gap-2">
						<Input
							name="name"
							value={data.name}
							onChange={handleDataChange}
							placeholder={t("newsletter.main.form.name")}
							required
						/>
						<Input
							name="company"
							value={data.company}
							onChange={handleDataChange}
							placeholder={t("newsletter.main.form.company")}
							required
						/>
						<Input
							name="email"
							type="email"
							value={data.email}
							onChange={handleDataChange}
							placeholder={t("newsletter.main.form.email")}
							required
						/>

						<span className="text-white text-sm">
							<Trans
								i18nKey="newsletter.main.note"
								components={{
									privacypolicy: <Link to="/privacy-policy/" />,
									termsofuse: <Link to="/terms-of-use/" />,
								}}
							/>
						</span>
					</div>

					<PrimaryBtn
						type="submit"
						disabled={isBtnDisabled || isSubmitting}
						className="bg-theme-orange hover:bg-theme-orange/80 lg:text-lg disabled:bg-neutral-200 disabled:text-neutral-400"
					>
						{isSubmitting
							? t("common.cta.subscribing") + "..."
							: t("common.cta.subscribe")}
					</PrimaryBtn>
				</form>
			)}

			{isSubmitted && !error && (
				<div className="lg:w-1/2 flex flex-col gap-6 text-white justify-center py-12 lg:py-0">
					<h3 className="text-4xl sm:text-5xl">
						{t("newsletter.main.success.title")}
					</h3>
					<p>{t("newsletter.main.success.desc")}</p>
					<p>
						<Trans
							i18nKey="newsletter.main.success.note"
							components={{
								anchor: <a />,
							}}
						/>
					</p>
				</div>
			)}

			{error && (
				<div className="lg:w-1/2 flex flex-col gap-6 text-white justify-center py-12 lg:py-0">
					<h3 className="text-4xl sm:text-5xl">{error}</h3>
				</div>
			)}

			<NewsletterIllustration className="w-[40%] hidden lg:inline-flex" />
		</section>
	)
}
